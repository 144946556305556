<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    mounted() {
        console.log("Form loading", this.$root.$el);
    }
};
</script>


