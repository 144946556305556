import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './http'
import store from './store'
import vueCustomElement from 'vue-custom-element'
import Transitions from 'vue2-transitions'
import ContactForm from './components/ContactForm'
import VueAnalytics from 'vue-analytics'

Vue.prototype.$http = http

Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS
})

Vue.use(vueCustomElement)
Vue.use(Transitions)

Vue.component('contact-form', ContactForm)

App.store = store
App.router = router
Vue.customElement('vue-contact-form-widget', App)

import 'document-register-element/build/document-register-element'