<template>
    <div class="vue-contact-wrapper">
        <slide-y-down-transition>
            <div class="contact-form" v-if="!hidden" :class="{ 'closed' : !open }">
                <div class="d-flex mb-2 justify-content-between">
                    <h3>Meer informatie of offerte aanvragen?</h3>
                    <a href="#" class="close" @click="hide">
                        <i class="fa fa-times"></i>
                    </a>
                </div>
                <a
                    href="#"
                    class="btn btn--white btn--block"
                    v-if="!open"
                    @click="open = true"
                >Wij helpen je graag verder</a>
                <div v-else>
                    <p>Hebt u vragen omtrent een product of wilt u een offerte op maat? Wij helpen je graag verder.</p>

                    <div
                        class="message"
                        v-if="feedback.message.length > 0"
                        :class="feedback.context"
                    >{{ feedback.message }}</div>

                    <form @submit.prevent="send" v-if="feedback.context != 'success'">
                        <div class="form-group">
                            <label for="first-name">Voornaam</label>
                            <input
                                type="text"
                                class="form-control"
                                id="first-name"
                                v-model="formData.name"
                            />
                        </div>
                        <div class="form-group">
                            <label for="email">E-mail</label>
                            <input
                                type="text"
                                class="form-control"
                                id="email"
                                v-model="formData.email"
                            />
                        </div>
                        <div class="form-group">
                            <label for="phone">Telefoonnummer</label>
                            <input
                                type="text"
                                class="form-control"
                                id="phone"
                                v-model="formData.phone"
                            />
                        </div>
                        <div class="form-group">
                            <label for="message">Bericht</label>
                            <textarea
                                class="form-control"
                                id="message"
                                rows="3"
                                v-model="formData.message"
                            ></textarea>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn--primary ml-auto" :disabled="sending">
                                <i class="fa fa-spin fa-circle-o-notch mr-2" v-if="sending"></i>
                                Verzenden
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </slide-y-down-transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formData: {
                name: "",
                email: "",
                phone: "",
                message: "",
                gdpr: "",
                url: ""
            },
            sending: false,
            feedback: {
                message: "",
                context: ""
            },
            open: false,
            hidden: true
        };
    },
    mounted() {
        this.formData.url = window.location.href;

        if (!localStorage.getItem("hide_cta")) {
            window.setTimeout(() => {
                this.hidden = false;
            }, 5000);
        }
    },
    methods: {
        send() {
            if (this.sending) {
                return false;
            }

            this.sending = true;

            this.$http
                .post(process.env.VUE_APP_API_ENDPOINT, this.formData)
                .then(res => {
                    console.log(res);
                    this.feedback = res.data.feedback;
                    this.sending = false;
                    console.log("Sending event to Google Analytics");
                    this.$ga.event({
                        eventCategory: "Form",
                        eventAction: "Sent",
                        eventLabel: "Offerte aanvragen",
                        eventValue: 1
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.feedback.message =
                        "Bericht kon niet verzonden worden. Probeer later opnieuw.";
                    this.feedback.context = "error";
                    this.sending = false;
                });
        },
        hide() {
            if (this.open) {
                this.open = false;
            } else {
                this.hideForever();
            }
        },
        hideForever() {
            localStorage.setItem("hide_cta", true);
            this.hidden = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.vue-contact-wrapper {
    @import "~font-awesome/css/font-awesome.min.css";
    @import "~bootstrap/scss/bootstrap.scss";
    @import "./../styles/main.scss";
}
</style>